import React from "react";

export interface JpmmpsSpinnerParamas {
    spinning?: boolean
}

export default function JpmmpsSpinner({ spinning }: JpmmpsSpinnerParamas) {
    return (
        <div id="selector-container_spinner-1">
            <div className="c-spinner c-spinner--full-page" aria-busy={spinning} aria-live="polite">
                <div className="c-spinner__loader">
                    <span className="c-spinner__element">
                        <span className="c-spinner__element">
                            <span className="c-spinner__element">
                                <span className="c-spinner__element">
                                    <span className="c-spinner__element">
                                        <span className="c-spinner__element">
                                            <span className="c-spinner__element">
                                                <span className="c-spinner__element">
                                                </span></span></span></span></span></span></span></span></div></div></div>)
}
