import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import JpmmpsSpinner from "../../components/JpmmpsSpinner";

export default function RedirectPage() {
  const [searchParams] = useSearchParams();
  const result = searchParams.get('result');
  const errorCode = searchParams.get('errorCode');
  const redirect = `${process.env.REACT_APP_MOBILEAPP_ID}://payment_redirect?result=${result}${errorCode ? '&errorCodes=' + errorCode : ''}`

  const [spinning, setSpinning] = useState(true);

  useEffect(() => {
    console.log(`Try to redirect to: ${redirect}`)
    window.location.href = `${redirect}`;

    const timer = setTimeout(() => setSpinning(false), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="jpmmps-body o-content-section u-pt-none u-mb-none">
      <div>
        <h5>Redirecting to your app...{ }</h5>
        <div className="u-text-fs-1 u-mb">If the redirect doesn&apos;t happen automatically, <a href={redirect}>click here</a>.</div>
      </div>

      <JpmmpsSpinner spinning={spinning} />
    </div>
  )
}
